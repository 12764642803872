// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBWo5_zgbMgKf5nbYrn749NgvNGkBri23k',
    authDomain: 'ao-docs-staging.firebaseapp.com',
    databaseURL: 'https://ao-docs-staging.firebaseio.com',
    projectId: 'ao-docs-staging',
    storageBucket: 'ao-docs-staging.appspot.com',
    messagingSenderId: '563231141203',
    appId: '1:563231141203:web:69fd2c6b041e95f93845a6',
    measurementId: 'G-0RJZXTDXBR',
  },
  clientId: '16006521034-qvqm4e4cdl5ihgk2sce4iahorj2agoe7.apps.googleusercontent.com',
  backend: {
    apiUrl: 'https://salesforce-connector.dev-euw2.aodocs.app',
    salesforceLoginSaveCredentialsMethod: '/api/v1/salesforce/auth/saveCredentials',
    salesforceTechSignIn: '/api/v1/salesforce/auth/salesforceTechSignIn',
    salesforceUserSignIn: '/api/v1/salesforce/auth/salesforceUserSignIn',
    salesforceConfigEndpoint: '/api/v1/salesforce/auth/config',
    googleTechSignIn: '/api/v1/google/auth/googleTechSignIn',
    googleUserSignIn: '/api/v1/google/auth/googleUserSignIn',
    firebaseTechSignIn: '/api/v1/aodocs/auth/firebaseTechSignIn',
    userCheckEndpoint: '/api/v1/aodocs/auth/check',
    tenantIdEndpoint: '/api/v1/tenant/id',
    getObjectFields: '/api/v1/salesforce-frontend/api/getObjectFields',
    saveRule: '/api/v1/salesforce-rules',
    updateRule: '/api/v1/salesforce-rules/{ruleId}',
    listRules: '/api/v1/salesforce-rules',
    getRule: '/api/v1/salesforce-rules/{ruleId}',
    deleteRule: '/api/v1/salesforce-rules/{ruleId}',
    listAODocsMatchingTypes: '/api/v1/aodocs-actions/salesforce-aodocs-types',
    listAvailableClasses: '/api/v1/aodocs-actions/available-classes',
    canCreateAODocsRule: '/api/v1/aodocs-actions/can-create-rule',
    getAodocsRule: '/api/v1/aodocs-rules/{ruleId}',
    saveAodocsRule: '/api/v1/aodocs-rules',
    updateAodocsRule: '/api/v1/aodocs-rules/{ruleId}',
    deleteAodocsRule: '/api/v1/aodocs-rules/{ruleId}',
    getSalesforceObject: '/api/v1/aodocs-rules/salesforce-object/{classId}',
    listAppRules: '/api/v1/rules',
    aodocs: {
      getLibraries: '/api/v1/aodocs/libraries',
      getLibrary: '/api/v1/aodocs/libraries/{libraryId}',
      getClasses: '/api/v1/aodocs/libraries/{libraryId}/classes',
      getClassMetadata: '/api/v1/aodocs/libraries/{libraryId}/classes/{classId}/metadata',
      getClassWorkflowStates: '/api/v1/aodocs/libraries/{libraryId}/classes/{classId}/workflow-states',
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
